import React, { ReactNode } from 'react';
import { CallToActionContainer } from './styles';

export interface CallToActionProps {
  children: ReactNode;
}

const CallToAction = (props: CallToActionProps) => {
  return <CallToActionContainer>{props.children}</CallToActionContainer>;
};

export default CallToAction;
