import React from 'react';
import { Hero, Layout, FullImageContent, Container, ShadowBoxes, GalleryWrapper, Button } from '../../components';
import { graphql } from 'gatsby';
import { HeroHeader, HeroSubHeader } from '../../components/Hero/styles';
import content from './content';
import {
  PaddingMedium,
  ContentHeader,
  ContentSubheader,
  HalfWidthContentParagraph,
  PaddingSmall,
  ContentHeaderNoMargin,
} from '../../styles';
import { COLOR } from '../../constants';
import useIsDesktop from '../../lib/isDesktop';
import { IGallery } from '../../components/GalleryWrapper';
import Video from '../../components/Video';
import CallToAction from '../../components/CallToAction';
import Fade from 'react-reveal/Fade';

const SolutionsPage = ({ data, path }: any) => {
  const isDesktop = useIsDesktop();
  const littleTheoGalleryImages = [
    data.littleTheoImage1.childImageSharp.fluid,
    data.littleTheoImage2.childImageSharp.fluid,
    data.littleTheoImage3.childImageSharp.fluid,
    data.littleTheoImage4.childImageSharp.fluid,
  ];

  const theoGalleryImages = [
    data.theoImage1.childImageSharp.fluid,
    data.theoImage2.childImageSharp.fluid,
    data.theoImage3.childImageSharp.fluid,
    data.theoImage4.childImageSharp.fluid,
    data.theoImage5.childImageSharp.fluid,
  ];

  const galleryWrapperContent: IGallery[] = [
    {
      ...content.gallery.littleTheo,
      galleryImages: littleTheoGalleryImages,
    },
    {
      ...content.gallery.theo,
      galleryImages: theoGalleryImages,
    },
  ];

  return (
    <Layout isDesktop={isDesktop} path={path} title={content.title}>
      <Hero
        desktopImage={data.heroImage.childImageSharp.fluid}
        mobileImage={data.heroMobileImage.childImageSharp.fluid}
        isDesktop={isDesktop}
        leftAligned={false}
      >
        <HeroHeader>{content.heroHeader}</HeroHeader>
        <HeroSubHeader>{content.heroSubHeader}</HeroSubHeader>
      </Hero>

      <PaddingMedium backgroundcolor={COLOR.WHITE} />
      <FullImageContent content={content.platform} image={data.platformImage.childImageSharp.fluid} />

      <GalleryWrapper content={galleryWrapperContent} />

      <Container paddingVertical={'0px'}>
        <ContentHeaderNoMargin>
          <Fade>{content.coreServices.header}</Fade>
        </ContentHeaderNoMargin>
      </Container>
      <FullImageContent
        content={content.coreServices.telematics}
        image={data.coreServicesTelematics.childImageSharp.fluid}
        reversed={true}
        paddingVertical={'40px'}
      />
      <FullImageContent
        content={content.coreServices.portal}
        image={data.coreServicesPortal.childImageSharp.fluid}
        paddingVertical={'40px'}
      />
      <FullImageContent
        content={content.coreServices.app}
        image={data.coreServicesApp.childImageSharp.fluid}
        reversed={true}
        paddingVertical={'80px'}
      />

      <PaddingSmall backgroundcolor={COLOR.WHITE} />

      <Container paddingVertical={'0px'} flexDirection={'column'}>
        <Fade>
          <ContentHeader>{content.additionalServices.header}</ContentHeader>
          <HalfWidthContentParagraph>{content.additionalServices.description}</HalfWidthContentParagraph>
        </Fade>
        <ShadowBoxes texts={content.additionalServices.shadowBoxTexts} triple={true} />
      </Container>

      <Container flexDirection={'column'}>
        <Fade>
          <ContentHeader>{content.howToSection.header}</ContentHeader>
          <HalfWidthContentParagraph>{content.howToSection.description}</HalfWidthContentParagraph>
          <Video url={content.howToSection.videoUrl} />
        </Fade>
      </Container>

      <PaddingMedium backgroundcolor={COLOR.WHITE} />

      <Fade>
        <CallToAction>
          <h2>{content.callToAction.header}</h2>
          <h3>{content.callToAction.subHeader}</h3>
          <Button text={content.callToAction.buttonText} link={content.callToAction.buttonLink} />
        </CallToAction>
      </Fade>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "solutions/solutions-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroMobileImage: file(relativePath: { eq: "solutions/solutions-hero-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    platformImage: file(relativePath: { eq: "solutions/platform.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    littleTheoImage1: file(relativePath: { eq: "solutions/little-theo-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    littleTheoImage2: file(relativePath: { eq: "solutions/little-theo-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    littleTheoImage3: file(relativePath: { eq: "solutions/little-theo-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    littleTheoImage4: file(relativePath: { eq: "solutions/little-theo-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theoImage1: file(relativePath: { eq: "solutions/theo-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theoImage2: file(relativePath: { eq: "solutions/theo-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theoImage3: file(relativePath: { eq: "solutions/theo-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theoImage4: file(relativePath: { eq: "solutions/theo-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theoImage5: file(relativePath: { eq: "solutions/theo-5.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coreServicesTelematics: file(relativePath: { eq: "solutions/coreservices-telematics.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coreServicesApp: file(relativePath: { eq: "solutions/coreservices-app.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coreServicesPortal: file(relativePath: { eq: "solutions/coreservices-portal.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SolutionsPage;
