import { IFullImageContent } from '../../components/FullImageContent/index';

interface ISolutionGallery {
  header: string;
  shadowBoxTexts: string[];
}

interface ISolutionsPageContent {
  title: string;
  heroHeader: string;
  heroSubHeader: string;
  platform: IFullImageContent;
  gallery: {
    littleTheo: ISolutionGallery;
    theo: ISolutionGallery;
  };
  coreServices: {
    header: string;
    telematics: IFullImageContent;
    app: IFullImageContent;
    portal: IFullImageContent;
  };
  additionalServices: {
    header: string;
    description: string;
    shadowBoxTexts: string[];
  };
  howToSection: {
    header: string;
    description: string;
    videoUrl: string;
  };
  callToAction: {
    header: string;
    subHeader: string;
    buttonText: string;
    buttonLink: string;
  };
}

const content: ISolutionsPageContent = {
  title: 'ThingCo Solutions',
  heroHeader: 'Telematics Solutions',
  heroSubHeader:
    'Next generation technology and hardware applied and designed using extensive expertise and unique practical knowledge.',
  platform: {
    header: "What's Provided",
    subheader:
      'At ThingCo we understand that individual partners have specific needs, which is why we can offer an entirely tailored service to suit and meet your demands.',
    description: `Our standard telematics service offering provides you with exceptional risk management propositions. We've worked hard to ensure our technology does the work for you, providing you with advanced solutions including validating class of use, identifying overnight parking, tracking mileage, and detecting speeding - all with the highest accuracy and reliability. 

    Since our devices are always working whilst remaining independent of vehicles and mobile phones, it ensures that the data we provide is optimised and controlled by you, empowering you with the ability to manage risks in real time. `,
  },
  gallery: {
    littleTheo: {
      header: 'Little Theo',
      shadowBoxTexts: [
        'A fully wireless telematics solution',
        'Solar energy powered',
        'Self-installed in under 60 seconds',
        'Full worldwide roaming SIM (2G and 3G available)',
        'No mobile phone required for data transmission. Data collected every second (1 Hz)',
        'Built in Amazon Lex voice technology allowing direct communication with the driver',
        'Tamperproof',
        '208 Hz accelerator for crash events',
        'Bluetooth option',
      ],
    },
    theo: {
      header: 'Theo',
      shadowBoxTexts: [
        'HD camera',
        'ADAS capabilities (speed of car in front, distance of car in front, lane type recorder)',
        'Professionally installed',
        'Full worldwide 4G roaming SIM',
        'No mobile phone required for data transmission. Data collected every second (1 Hz)',
        'Built in Amazon Lex voice technology allowing direct communication with the driver',
        'Tamperproof',
        '100 Hz accelerator for crash events',
      ],
    },
  },
  coreServices: {
    header: 'Core Services',
    telematics: {
      subheader: 'ThingCo hosts a fully independent and isolated telematics platform',
      description: `Using the ThingCo team's vast experience, we're able to provide any partner with the tools and technology to create and enhance their product offering in addition to providing the expertise and knowledge needed to extract the asset value from ThingCo's Theo devices.

      We deliver a state-of-the-art, fully encrypted, telematics platform built on AWS' IoT serverless technology meaning the platform is scalable to any size, anywhere in the world. We can directly connect to your policy and claims solutions by integrating into your system using secure APIs, all whilst supplying you with the data collected by our devices, making real time risk mitigation possible.`,
    },
    portal: {
      subheader:
        "The fully white labelled telematics management portal gives our partners the ability to observe their customers' driving behaviour on demand",
      description:
        'Our platform empowers you by building up a stronger, more detailed risk profile of your customers. Our sophisticated crash algorithm enables Theo to recognise and distinguish between real and false events while customers can take advantage of the Amazon Lex voice assistant to confirm or deny the event. This reduces the number of false positives, while proactively alerting you to accidents in real time. We provide you with the information you need to capture third parties quickly and efficiently, thereby reducing your average claims cost.',
    },
    app: {
      subheader: 'The fully white labelled customer app gives your customers access to a host of unique features.',
      description:
        'Our app puts your customers in control of their driving, enabling you to concentrate your resources elsewhere. Granting customers access to their personalised driving score, mapped journeys, and events such as accidents and speeding gives them the ability to observe and adapt their driving behaviour to become safer over time. In turn, this reduces claims frequency and lowers their risk profile, providing you with safer drivers and the opportunity to offer more competitive premiums.',
    },
  },
  additionalServices: {
    header: 'Additional Services',
    description:
      'At ThingCo we understand that individual firms have specific needs, which is why we can offer an entirely tailored service to suit and meet your demands.',
    shadowBoxTexts: [
      'Tailored smart FNOL process',
      'Analytical services to identify and manage toxic risks',
      'Driver intervention management',
      'Customised reporting and hardware solutions',
      'Telematics costs & customer risk reduction consultancy',
      'Fully white labelled customer app and platform',
      'Packaging and distribution of solution to policyholders',
      'Custom integration with your systems',
      'Customer reward incentivisation',
    ],
  },
  howToSection: {
    header: 'How to Install Theo',
    description: 'See how simple it is to install the Theo device into any vehicle. This video breaks down the steps.',
    videoUrl: 'https://www.youtube.com/embed/jYo-8IkvvxM',
  },
  callToAction: {
    header: 'Contact Us',
    subHeader: 'If you have any queries about ThingCo or our service, please contact us.',
    buttonText: 'Get in touch',
    buttonLink: '/contact',
  },
};

export default content;
