import styled from 'styled-components';
import { COLOR } from '../../constants';
import { mobileMixin } from '../../styles';

export const CallToActionContainer = styled.div`
  background-color: ${COLOR.LIGHTBLUE};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 70px 70px 40px;

  a {
    padding: 0px 70px;
  }

  h2,
  h3 {
    color: ${COLOR.WHITE};
    margin-block-start: 0;
    margin-block-end: 0;
    white-space: normal;
    text-align: center;

    max-width: 40%;

    ${mobileMixin(`
      max-width: 100%;
    `)}
  }
`;
